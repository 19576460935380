import React from 'react';
import { Components } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const defaultTheme = createTheme();

export const palette = {
  primary: {
    main: '#00965D',
  },
  secondary: {
    main: '#22795F',
  },
  warning: {
    main: '#FA9454',
    contrastText: '#fff',
  },
  error: {
    main: '#E75A5A',
  },
  success: {
    main: '#62C797',
    contrastText: '#fff',
  },
  info: {
    main: '#61BBD7',
    contrastText: '#fff',
  },
  divider: "#EAEEEA"
};

export const components: Components = {
  MuiTypography: {
    styleOverrides: {
      root: {
        fontWeight: 400,
      },
      h1: {
        fontSize: '50px',
        fontWeight: 700,
      },
      h2: {
        fontSize: '46px',
        fontWeight: 700,
      },
      h3: {
        fontFamily: 'BNPPSansCondV2, BNPPSans',
        fontSize: '40px',
        fontWeight: 700,
        color: '#fff',
        textTransform: 'uppercase',
      },
      h4: {
        fontFamily: 'BNPPSansCondV2, BNPPSans',
        fontWeight: 700,
        fontSize: 30,
        lineHeight: '30.26px',
      },
      h5: {
        fontWeight: 700,
        fontSize: 18,
      },
      h6: {
        fontSize: 18,
      },
      body1: {
        fontSize: '15px',
        fontFamily: 'BNPPSans',
        fontWeight: 300,
        // textAlign: 'justify'
      }
    }
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: 'inherit',
        boxShadow: "none"
      }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.2)",
      }
    }
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        background: `linear-gradient(90deg, ${palette.primary.main} 0%, ${palette.secondary.main} 100%)`,
        padding: '10px 40px 10px 40px',
        color: '#fff',
        textTransform: 'uppercase',
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          border: `solid 1px ${palette.secondary.main}`,
          borderRadius: '10px',
        }
      }
    }
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ExpandMoreIcon />, 
    },
    styleOverrides: {
      root: {
        backgroundColor: palette.divider,
        color: palette.primary.main,
        textTransform: 'uppercase',
        borderRadius: '10px',
        '&.Mui-expanded': {
          backgroundColor: palette.secondary.main,
          color: defaultTheme.palette.common.white,
        }
      },
      expandIconWrapper: {
        color: 'inherit',
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: palette.primary.main,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
        marginBottom: 20
      },
      asterisk: {
        color: palette.error.main
      }
    }
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottomColor: palette.primary.main,
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: '10px 20px',
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2);',
        borderRadius: '10px!important',
        '.MuiInputBase-input': {
          padding: 0
        }
      }
    }
  },
  MuiLoadingButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        '&.MuiButton-containedPrimary': {
          background: `linear-gradient(90deg, ${palette.primary.main} 0%, ${palette.secondary.main} 100%)`,
        }
      },
    }
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        // borderRadius: '5px!important',
        boxShadow: 'none!important',
        fontWeight: '400 !important',
      },
    }
  },
  MuiButton: {
    styleOverrides: {
      sizeSmall: {
        textTransform: 'none',
      },
      sizeMedium: {
        fontSize: 18,
      },
      outlined: {
        borderColor: palette.primary.main,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: "#EBEBEB"
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...((ownerState.variant as any) === 'contained' && {
          backgroundColor: palette.primary.main,
          color: defaultTheme.palette.common.white,
        })}),
      sizeSmall: {
        height: 20,
        width: 20,
        
      }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...((ownerState.fontSize as any) === 'extra-large' && {
          fontSize: 60,
        }),
      }),
      fontSizeSmall: {
        fontSize: 20
      },
      fontSizeMedium: {
        fontSize: 25
      },
      fontSizeLarge: {
        fontSize: 40,
      },
      
    }
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        fontSize: '15px',
        textAlign: 'justify',
      },
    }
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        fontFamily: 'BNPPSans',
      },
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: '15px',
        fontWeight: 300,
      }
    }
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      sx: {
        boxShadow: "none"
      }
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'always',
    },
    styleOverrides: {
      root: {
        fontFamily: 'BNPPSans',
        fontSize: '12px',
        fontWeight: 400,
      },
      underlineAlways: {
        textUnderlineOffset: 2,
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '& .MuiTypography-root': {
          fontFamily: 'BNPPSans',
          fontSize: '14px',
          fontWeight: 400,
        }
      },
    }
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        textAlign: "left"
      },
    }
  }
}