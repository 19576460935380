import React from 'react';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import { Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom/dist';

const FeatureSyncFilterButton = ({ onClick, size, ...otherProps }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }

    const newSearchParams = {} as any;

    if (!searchParams.get('sync')) {
      newSearchParams.sync = true;
    }

    if (searchParams.get('segment')) {
      newSearchParams.segment = searchParams.get('segment');
    }

    setSearchParams(newSearchParams);
  }

  return (
    <Button size={size} onClick={handleClick} {...otherProps}>
      <SyncAltRoundedIcon fontSize={size}/>
    </Button>
  )
}

export default FeatureSyncFilterButton;