import React from 'react';
import { Form } from 'formik';
import AlertError from './AlertError';
import { FormContext } from './Create';
import AlertSuccess from './AlertSuccess';

interface SimpleFormProps {
  title?: string;
  disableScrollToTop?: boolean;
  children: React.ReactNode;
  errors?: any;
  previousLink?: string;
  submitText?: string;
  actions?: React.ReactNode | any;
  successMessage?: string;
  withAlert?: boolean;
}

const SimpleForm = ({
  title,
  disableScrollToTop,
  previousLink,
  submitText,
  actions,
  withAlert,
  successMessage,
  children,
  ...otherProps
}: SimpleFormProps) => {
  const mutation = React.useContext(FormContext);
  const response = React.useMemo(() => {
    const error = mutation?.error as any;
    return error?.response;
  }, [mutation]);

  return (
    <Form {...otherProps}>
      {(response && withAlert) && (
        response?.status !== 500 ?
          <AlertError
            sx={{ mt: 2, mb: 3 }}
            scrollIntoView={!disableScrollToTop}
            message={response.data.message}
          />
        :
          <AlertError
            sx={{ mt: 2, mb: 3 }}
            scrollIntoView={!disableScrollToTop}
            message={"Une erreur interne est survenue. Veuillez contacter un administrateur si le problème persiste."}
          />
      )}
      {mutation?.isSuccess && withAlert && (
        <AlertSuccess
          sx={{ mt: 2, mb: 3 }}
          message={successMessage}
          scrollIntoView
        />
      )}
      {typeof children === 'function' ? (
        <FormContext.Consumer>{children}</FormContext.Consumer>
      ) : (
        children
      )}
      {actions && React.cloneElement(actions, { mutation })}
    </Form>
  );
};

export default SimpleForm;
