import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { palette, components } from './config/theme.config';
import routesConfig from './config/routes.config';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'react-router-auth-config';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { QueryProvider } from '@agencelafusee/react-query-list';
import { ReactQueryDevtools } from 'react-query/devtools';
import GlobalStyles from "@mui/material/GlobalStyles";
import './App.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import NotFoundPage from './pages/NotFoundPage';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export const theme = createTheme({
  typography: {
    "fontFamily": `BNPPSans`,
    "fontWeight": 400,
  },
  palette,
  components
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});

const dataProvider = {
  getItems: (data) => data?.data || [],
  getPaginationMeta: (data) => ({
    countPages: data?.count_pages || 1,
    page: data?.currentPage || 1,
    countItems: data?.count_items || 0,
    perPage: data?.itemsPerPage || 25,
  }),
}

const App = () => {
  dayjs.locale('fr');

  return (
    <QueryClientProvider client={queryClient}>
      <QueryProvider dataProvider={dataProvider}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <GlobalStyles
            styles={(theme) => ({
              '&::-webkit-scrollbar': {
                width: 5,
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-thumb': {
                background: theme.palette.primary.main, 
                borderRadius: 10,
              },
              }
            )}
          />
          <BrowserRouter>
            <AppRoutes
              rootAuthenticated='/login'
              rootUnauthenticated='/home'
              routesConfig={routesConfig}
              notFoundPage={<NotFoundPage />}
            />
          </BrowserRouter>
        </ThemeProvider>
        </LocalizationProvider>
      </QueryProvider>
    </QueryClientProvider>
  )
};

export default App