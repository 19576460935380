import React from 'react';
import { FormikBootstrapInput, FormikSubmit } from '@agencelafusee/mui-admin-modules';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Field } from 'formik';
import { Alert, Button, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import Create from '../../components/shared/Create';
import axios from '../../api/axios';
import SimpleForm from '../../components/shared/SimpleForm';
import { AuthLayout } from '../../layouts';
import * as Yup from 'yup';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
 
const PasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Le mot de passe doit comporter au moins 8 caractères.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
      'Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial.'
    )
    .required('Ce champ est obligatoire.'),
      confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas.')
      .required('Ce champ est obligatoire.'),
 });

const Page = () => {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (!state?.email) {
      navigate('/recovery/request-token');
    } else if (!state?.token) {
      navigate('/recovery/enter-token');
    }
  }, []);

  const handleSuccess = () => {
    setSuccess(true);
  }

  const handleError = (data) => {
    setError(data?.response?.data?.message);
  }

  const handleSubmit = (mutate, values) => {
    mutate({
      token: state.token,
      new_password: values.password,
      new_password_confirm: values.confirmPassword,
    });
  }

  return (
    <AuthLayout
      title="Reset mot de passe"
      description={success ? "Mot de passe modifié avec success" : "Modifier votre mot de passe"}
      subDescription={"Le mot de passe doit être composé d’au moins 8 caractères de type différent (lettres, chiffres, caractères spéciaux, majuscules, minuscules)."}
      links={!success && [
        {
          to: '/login',
          label: 'Connexion',
        },
        {
          to: '/register',
          label: 'Créer un compte',
        }
      ]}
    >
      {success ? (
        <Button component={Link} to="/login">Se connecter</Button>
      ): (
        <Create
          initialValues={{ password: '', confirmPassword: '' }}
          query={(data) => axios.put('/auth/reset/confirm', data)}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onError={handleError}
          validationSchema={PasswordSchema}
        >
          <SimpleForm>
            <Stack sx={{ marginTop: "20px" }}>
              <Stack
                spacing={4}
                maxWidth={205}
                direction="column"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <Field
                  component={FormikBootstrapInput}
                  required
                  type={showPassword ? 'text' : 'password'}
                  label="Mot de passe"
                  placeholder="Mot de passe"
                  inputProps={{ autoComplete: 'new-password' }}
                  name="password"
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Field
                  component={FormikBootstrapInput}
                  required
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirmer le mot de passe"
                  placeholder="Confirmer le mot de passe"
                  inputProps={{ autoComplete: 'off' }}
                  name="confirmPassword"
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormikSubmit>
                  Envoyer
                </FormikSubmit>
                {error &&
                  <Alert severity='error'>{error}</Alert>
                }
                <Typography>
                  <Typography component="span" color="error">*</Typography>
                  {" "}Champ obligatoire
                </Typography>
              </Stack>
            </Stack>
          </SimpleForm>
        </Create>
      )}
    </AuthLayout>
  );
}

export default Page;
