export class CatalogService {
  static getProduct(products, productId) {
    if (products) {
      return products.find(product => product.slug === productId);
    }
  }
  
  static getService(products, productId, serviceId) {
    const productFound = CatalogService.getProduct(products, productId);

    if (productFound) {
      return productFound.services.find(item => item.slug === serviceId);
    }
  }

  static getComponent(products, productId, serviceId, componentId) {
    const service= CatalogService.getService(products, productId, serviceId);

    if (service) {
      return service.components?.find(component => component.slug === componentId);
    }
  }

  static getData(products, productId, serviceId, componentId) {
    return {
      product: CatalogService.getProduct(products, productId),
      service: CatalogService.getService(products, productId, serviceId),
      component: CatalogService.getComponent(products, productId, serviceId, componentId),
    }
  }

  static test2(array, items, search, root, parents, callback) {
    items.forEach((item) => {
      if (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
        if (array[array.length - 1]?.length === parents.length) {
          array[array.length - 1].push({
            name: item.name,
            slug: item.slug,
            path: item.slug && (item.services || item.components || item.features) && `${root}/${item.slug}`,
          });
        } else {
          const parentItems = parents.map((parentItem) => (
            {
              name: parentItem.name,
              slug: parentItem.slug,
              path: parentItem.slug && (parentItem.services || parentItem.components || parentItem.features) && `${parentItem.root}/${parentItem.slug}`,
            }
          ));
          array.push([
            ...parentItems,
            {
              name: item.name,
              slug: item.slug,
              path: item.slug && (item.services || item.components || item.features) && `${root}/${item.slug}`,
            }
          ]);
        }
      }
      callback({ ...item, root });
    })
  }

  static search(items, search) {
    let results = [[]];

    CatalogService.test2(
      results,
      items,
      search,
      '',
      [],
      (product) => {
        CatalogService.test2(
          results,
          product.services,
          search,
          '/services',
          [product],
          (service) => {
            CatalogService.test2(
              results,
              service.components,
              search,
              '/components',
              [product, service],
              (component) => {
                CatalogService.test2(
                  results,
                  component.features,
                  search,
                  '/features',
                  [product, service, component],
                  () => {}
                )
              }
            )
          }
        )
      }
    );

    return results.reduce(
      (accumulator, currentValue) => {
        if (currentValue.length > 1) {
          const name = currentValue[1].name;
          const item = accumulator.find(item => item.name === name)
          if (item) {
            item.breadcrumbs.push(currentValue)
          } else {
            accumulator.push({ name, breadcrumbs: [currentValue]});
          }
        }
        return accumulator;
      }, []
    );
  }

  static highlight(text, search) {
    const indexOfFirst = text.toLowerCase().indexOf(search.toLowerCase());
    const searchText = text.slice(indexOfFirst, indexOfFirst + search.length);
    return text.replace(searchText, `<strong style="color:black;">${searchText}</strong>`);
  }
}
