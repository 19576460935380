import React from 'react';
import {
  usePopupState,
  bindHover,
} from 'material-ui-popup-state/hooks'
import InfoIcon from '@mui/icons-material/Info';
import SegmentStatusIcon from './SegmentStatusIcon';
import { Button, List, ListItem, ListItemAvatar, ListItemText, Stack, styled } from '@mui/material';

const ListItemTextStyled = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontWeight: 400
  },
  '& .MuiListItemText-secondary': {
    fontWeight: 300,
    color: "#000",
    textAlign: "left"
  }
});

const SegmentInfoListItem = ({ status, primaryText, secondaryText }) => (
  <ListItem alignItems="flex-start">
    <ListItemAvatar sx={{ textAlign: 'center' }}>
      <SegmentStatusIcon status={status} />
    </ListItemAvatar>
    <ListItemTextStyled
      primary={primaryText}
      secondary={secondaryText}
    />
  </ListItem>
);

const SegmentInfoSelect = (props) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'segmentInfoMenu' })

  return (
    <Stack 
      sx={{ 
        width: '100%', 
        position: "relative",
        "& > .MuiButtonBase-root:hover + .MuiList-root" : {
          display: "block"
        }
      }}
    >
      <Button
        variant="contained"
        {...bindHover(popupState)}
        {...props}
        sx={{ width: '100%' }}
      >
        <InfoIcon fontSize={props.size} />
      </Button>
      <List 
        sx={{ 
          display: "none", 
          position: "absolute",
          backgroundColor: "white",
          top: "28px",
          right: { xs: 0, md: "initial"},
          zIndex: 1
        }}
      >
        <SegmentInfoListItem
          status="S"
          primaryText="Standard"
          secondaryText="C’est l’offre standard à proposer à tous les clients du segment concerné. C’est l’offre par défaut préconisée par Arval"
        />
        <SegmentInfoListItem
          status="O"
          primaryText="Option"
          secondaryText="C’est l’alternative au standard qu’il est possible de proposer aux clients du segment concerné."
        />
        <SegmentInfoListItem
          status="DE"
          primaryText="Défensif"
          secondaryText="Dérogation systématiquement soumise à l’accord du Manager."
        />
        <SegmentInfoListItem
          status="I"
          primaryText="Interdit"
          secondaryText="Ce produit ne peut pas être commercialisé pour ce segment"
        />
      </List>
    </Stack>
  )
}

export default SegmentInfoSelect;