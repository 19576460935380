import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthCode from 'react-auth-code-input';
import { Box, FormHelperText, Stack } from '@mui/material';
import { FormikSubmit } from '@agencelafusee/mui-admin-modules';
import Create from '../../components/shared/Create';
import axios from '../../api/axios';
import { AuthLayout } from '../../layouts';
import SimpleForm from '../../components/shared/SimpleForm';

const Page = () => {
  const token = React.useRef('');
  const { state } = useLocation()
  const navigate = useNavigate();
  
  React.useEffect(() => {
    if (!state?.email) {
      navigate('/recovery/request-token');
    }
  }, []);

  const handleSubmit = (mutate, values) => {
    mutate(values.token);
    token.current = values.token;
  }

  const handleSuccess = () => {
    navigate('/recovery/reset-password', {
      state: {
        token: token.current,
        email: state.email,
      }
    });
  }

  const handleOnChange = (value, formik) => {
    if (!formik.touched.token) {
      formik.setFieldTouched('token', true);
    }
    
    formik.setFieldValue('token', value);
  };

  return (
    <AuthLayout
      title="Entrer le token"
      description="Verifier votre mail"
      links={[
        {
          to: '/login',
          label: 'Connexion',
        },
        {
          to: '/register',
          label: 'Créer un compte',
        }
      ]}
    >
      <Create
        initialValues={{ token: '' }}
        query={(data) => axios.get(`/auth/reset/token?token=${data}&email=${state.email}`)}
        enableReinitialize
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      >
        {(props) => (
          <SimpleForm withAlert>
            <Box
              sx={{
                '& input::-webkit-outer-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
                '& input::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
                '& input': {
                  textAlign: 'center',
                  border: 'solid',
                  borderColor: 'divider',
                  borderRadius: 0.5,
                  height: 40,
                  width: 45,
                  margin: 0.5,
                }
              }}
            >
              <Stack spacing={3} direction="column" textAlign="center" alignItems="center" justifyContent="center">
                <AuthCode allowedCharacters="numeric" value={props.values.token} onChange={(value) => handleOnChange(value, props)} />
                {props.touched.token && props.errors.token && (
                  <FormHelperText
                    error
                  >
                    {props.errors.token}
                  </FormHelperText>
                )}
                <FormikSubmit>
                  Envoyer
                </FormikSubmit>

              </Stack>
            </Box>
          </SimpleForm>
        )}
      </Create>
    </AuthLayout>
  )
}

export default Page;