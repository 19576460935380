import React from 'react'
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { bindMenu } from 'material-ui-popup-state/hooks';
import CascadingContext from './CascadingContext';

export default function CascadingMenu({ popupState, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext)
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  )

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu 
        sx={{ 
          "& .MuiPopover-paper": {
            width: 280,
            borderRadius: '0 10px 10px 0',
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.06)"
          }
        }}
        {...props}
        {...bindMenu(popupState)} 
      />
    </CascadingContext.Provider>
  )
}