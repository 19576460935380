import React from 'react';
import { SvgIcon } from '@mui/material';

const ForbiddenIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <rect width="20" height="20" rx="10" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.09204 9.87891C5.09204 9.32662 5.53976 8.87891 6.09204 8.87891H13.9077C14.46 8.87891 14.9077 9.32662 14.9077 9.87891V10.1216C14.9077 10.6739 14.46 11.1216 13.9077 11.1216H6.09204C5.53976 11.1216 5.09204 10.6739 5.09204 10.1216V9.87891Z" fill="white"/>
    </SvgIcon>
  )
}

export default ForbiddenIcon;
