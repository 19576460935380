import React, { useState } from 'react'
import { Grid, Stack } from '@mui/material'
import { TopMenu }  from '../components/menus'
import Axios from '../api/axios';
import { useQuery } from 'react-query';
import CatalogSearchInput from 'components/catalog/SearchInput';
import CatalogSearchMenu from 'components/catalog/SearchMenu';
import Head from 'components/shared/Head';

type CatalogLayoutProps = {
  children: any;
  action?: any;
  title: any;
  description?: any;
}

const CatalogueLayout = ({ title, description, children, action }: CatalogLayoutProps) => {
  const [open, setOpen] = useState(false);

  const { isLoading, error, data } = useQuery('repoData', () =>
    Axios.post('/catalogue').then(res => res.data.data[0])
  )

  return (
    <>
      <Head title={title} description={description} />
      <TopMenu data={data} isLoading={isLoading} error={error} />
      <Grid container>
        <Grid 
          item 
          sm={12}
          md={12}
          bgcolor='#FCFCFC'
        >
          <Stack sx={{ position: 'relative' }}>
            <Stack 
              m={ 2 } 
              mb={4} 
              direction={{ xs: "column", md: "row" }} 
              justifyContent="space-between" 
              zIndex={200} 
              spacing={{ xs: 2, md: 0 }}
            >
              <CatalogSearchInput sx={{ width: {xs: '100%', md: '33%'} }} onClick={() => setOpen(!open)} />
              <CatalogSearchMenu open={open} onClose={() => setOpen(false)} />
              {action}
            </Stack>
            <Stack m={2}>
              {children}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default CatalogueLayout