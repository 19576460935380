import axios from "axios";
import { userSlice, defaultStore } from 'react-router-auth-config';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
});

Axios.interceptors.request.use(
  async (config) => {
    const user = JSON.parse(localStorage.getItem('rrac-user'));

    if (user) {
      config.headers = {
        authorization: `Bearer ${user.token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error),
);

Axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error && error.response) {
      const { status } = error.response;

      if (status === 401) {
        localStorage.removeItem('rrac-user');
        defaultStore.dispatch(userSlice.actions.update(null));
      }
    }

    return Promise.reject(error);
  },
);

export default Axios;