import React, { useState, useEffect } from 'react';
import { ListItem, ListItemText, Skeleton, Stack, styled, IconButton, Alert } from '@mui/material';
// import DownloadIcon from '@mui/icons-material/Download';
import RestoreIcon from '@mui/icons-material/Restore';
import dayjs from 'dayjs';
import { DownloadIconButton } from '@agencelafusee/mui-admin-modules';
import axios from '../../api/axios';
import DownloadIcon from 'icons/DownloadIcon';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface FileListItemProps {
  item?: any;
  refetch: any;
}

const DownloadIconStyled = styled(DownloadIcon)(({ theme }) => ({
  '& > path': {
    fill: "white",
  }
}));

const DownloadIconButtonStyled = styled(DownloadIconButton)(({ theme }) => ({
  '&:hover > svg > path': {
    fill: theme.palette.primary.main
  }
}));

const DeleteIconButtonStyled = styled(IconButton)(({ theme }) => ({
  '&:hover > svg > path': {
    fill: theme.palette.primary.main
  }
}));

const FileListItem = ({ item, refetch }: FileListItemProps) => {
  const [ isSuccess, setIsSuccess ] = useState(null);
  const [ isError, setIsError ] = useState(null);

  useEffect(() => {
    if (item) {
      setIsSuccess(null);
      setIsError(null);
    }
  }, [item]);

  const getFileBlob = (url: string) => {
    return axios.get(`/catalogue/imports/${item.id}${url}`, { responseType: 'blob'}).then(res => res.data)
  }

  const handleRestore = ( id: number ) => {
    axios.get(`/catalogue/imports/${id}/restore`, { responseType: 'blob'})
    .then(res => {
      setIsSuccess('Contenu du catalogue restauré.');
      return res.data
   })
    .catch(error => {
      setIsError('Une erreur est survenue.');
      console.error(error.response.data.error)
   })
  }

  const handleDelete = () => {
    axios.delete(`/catalogue/imports/${item.id}/delete`)
      .then((res: any) => {
        if(res?.status === 200) {
          refetch();
        }
      });
  }

  return (
    <ListItem sx={{ flexDirection: { xs: 'column', sm: 'row' }}}>
      <ListItemText
        sx={{ width: {xs: '100%', md: "33.33%"}, textAlign: { xs: "center", md: "left"} }}
        primaryTypographyProps={{ fontWeight: 400 }}
        primary={
          !item ? (
          <Skeleton animation="wave" height={20} width="30%" />
          ) : dayjs(item.date).format('DD/MM/YYYY')}
      />
      <ListItemText
        sx={{ width: {xs: '100%', md: "33.33%"}, textAlign: 'center' }}
        secondaryTypographyProps={{ fontWeight: 300, fontSize: 15 }}
        secondary={!item ? (
          <Skeleton animation="wave" height={20} width="50%" />
        ) : item?.filename}
      />
      {item ? (
        <Stack width={{xs: '100%', md: "33.33%"}} justifyContent="flex-end" spacing={1} direction={{xs: "column", md: "row"}}>
          <div>
            {isSuccess &&
              <Alert severity='success'>{isSuccess}</Alert>
            }
            {isError &&
              <Alert severity='error'>{isError}</Alert>
            }
          </div>
          <Stack direction="row" spacing={1} justifyContent={{ xs: "center" }}>
            <div>
              <IconButton onClick={() => handleRestore(item?.id)} variant="contained">
                <RestoreIcon />
              </IconButton>
            </div>
            <div>
              <DownloadIconButtonStyled
                variant="contained"
                blob={() => getFileBlob('/download')}
                filename="Download"
              >
                <DownloadIconStyled />
              </DownloadIconButtonStyled>
            </div>
            <div>
              <DeleteIconButtonStyled
                variant="contained"
                onClick={handleDelete}
                filename="Download"
              >
                <DeleteOutlineOutlinedIcon />
              </DeleteIconButtonStyled>
            </div>
          </Stack>
        </Stack>
      ): (
        <Stack spacing={2} direction="row">
          <Skeleton variant="circular" height={30} width={30} />
          <Skeleton variant="circular" height={30} width={30} />
        </Stack>
      )}
    </ListItem>
  )
}

export default FileListItem;