import React from 'react'
import { Card, CardContent, CardHeader, Typography } from '@mui/material'

interface CardProps {
  title: string;
  align: string;
  width: number;
  children: any;
  sx: any;
}

const CardTitle = ({ 
  title,
  align,
  width,
  children,
  sx
}: CardProps) => (
  <Card 
    sx={{
      width: {
        sm: width ? width : 'initial' 
      } 
    }}
  >
    <CardHeader
      title={<Typography variant="h3">{title}</Typography>}
      sx={{ textAlign: align ? align : 'initial', ...sx }}
    />
    <CardContent sx={{ padding: 5 }}>
      {children}
    </CardContent>
  </Card>
);

export default CardTitle;