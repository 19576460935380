import React from 'react';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';

const ServiceFilterButton = ({ value, ...otherProps }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleClick = () => {
    navigate(`/catalog/${params.product}/services/${params.service}/components/${value}${location.search}`)
  }

  return (
    <Button
      variant={params.component === value ? 'contained' : 'outlined'}
      onClick={handleClick}
      {...otherProps}
    />
  )
}

export default ServiceFilterButton;