import React from 'react';
import { SvgIcon } from '@mui/material';

const DownloadIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 283.46 283.46" {...props}>
      <path d="m68.25,210.98c-2.34,0-4.25-1.91-4.25-4.25v-36.48c0-2.34,1.91-4.25,4.25-4.25s4.25,1.91,4.25,4.25v32.23h138.75v-32.23c0-2.34,1.91-4.25,4.25-4.25s4.25,1.91,4.25,4.25v36.48c0,2.34-1.91,4.25-4.25,4.25H68.25Z" fill="#00965d"/>
      <path d="m119.68,97.83l-4.16-37c-.59-5.29,3.55-9.92,8.87-9.92h35.63c4.53,0,8.05,3.96,7.52,8.46l-4.55,38.47h24.33c5.41,0,8.57,6.1,5.46,10.52l-43.3,61.48c-3.79,5.38-11.76,5.4-15.58.04l-43.01-60.33c-3.43-4.81-.02-11.49,5.89-11.54l22.89-.17Z" fill="#00965d"/>
    </SvgIcon>
  )
}

export default DownloadIcon;
