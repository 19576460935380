import React from 'react';
import { CatalogueLayout } from '../layouts';
import { SegmentFilterSelect } from '../components/catalog';
import { Grid, Stack, Typography, Button } from '@mui/material'
import { CardTitle } from '../components/card'
import { useQuery } from 'react-query';
import axios from '../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import Create from '../components/shared/Create';
import SimpleForm from '../components/shared/SimpleForm';
import { Field } from 'formik';
import { FormikBootstrapInput, FormikSubmit } from '@agencelafusee/mui-admin-modules';
import HomeSliderFieldArray from '../components/HomeSliderFieldArray';
import HomeNewsFieldArray from '../components/HomeNewsFieldArray';
import { object, array, string } from 'yup';
import dayjs from 'dayjs';

const schema = object().shape({
  featured: array().of(
    object().shape({
      title: string().required('Ce champs est obligatoire'),
      content: string().required('Ce champs est obligatoire')
    }))
    .nullable()
  })

const HomeEdit = () => {
  const navigate = useNavigate();
  const { data } = useQuery('homeEdit', () => axios.get('/content/home').then(res => res.data?.data));

  if(!data) {
    return null;
  }

  const handleSubmit = (mutate, values) => {
    mutate(values);
  }

  const handleSuccess = () => {
    navigate('/home');
  }

  return (
    <CatalogueLayout 
      title="Accueil"
      action={
        <SegmentFilterSelect style={{ position: { md: 'absolute' } }} sx={{ borderRadius: 3, textTransform: 'initial' }} />
      }
    >
      <Create
        initialValues={{
          welcome: data?.welcome || '',
          featured: data?.featured || [],
          news: data?.news || [],
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        query={(data) => axios.put('/content/home', data)}
      >
        <SimpleForm
          withAlert
        >
          <Grid container spacing={4}>
            <Grid item md={12} justifyContent="flex-end">
              <Button
                component={Link}
                to="/home"
                variant="contained"
              >
                Retour
              </Button>
            </Grid>
            <Grid item md={12}>
              {/* welcome */}
              <CardTitle title="Bienvenue">
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 5, md: 10 }}>
                  <Stack width={{ md: '50%' }}>
                    <Field
                      component={FormikBootstrapInput}
                      placeholder=""
                      label="Contenu"
                      multiline
                      rows={8}
                      name="welcome"
                      variant="outlined"
                    />
                  </Stack>
                  <Stack
                    width={{ md: '50%' }}
                    justifyContent="center"
                    sx={{
                      border: "1px solid",
                      borderColor: "primary.main",
                      borderRadius: 3,
                      padding: 3
                    }}
                    spacing={2}
                  >
                    <Typography>
                    Le catalogue recense et présente les seuls produits et services commercialisables et accessibles selon votre segment commercial.
                    </Typography>
                    <Typography fontWeight="bold" color="secondary">
                      Version actuelle en ligne: {dayjs(data?.last_updated_at).format('LL HH:mm')}
                    </Typography>
                  </Stack>
                </Stack>
              </CardTitle>
            </Grid>

            {/* Feature */}
            <Grid item xs={12} md={6}>
              <CardTitle 
                title="A la Une"
                align='center' 
                sx={{ background: `linear-gradient(90.16deg, #FA9454 3.05%, #EB6411 80.35%)`}}
              >
                <Stack spacing={4}>
                  <Typography align="center" fontWeight="bold">Découvrez ici les dernières actualités Produits et Services</Typography>
                  <HomeSliderFieldArray />
                </Stack>

              </CardTitle>
            </Grid>

            {/* New */}
            <Grid item xs={12} md={6}>
              <CardTitle 
                title="Nouveautés"
                align='center'
                sx={{ background: `linear-gradient(90.16deg, #61BBD7 3.05%, #10A2CE 80.35%)`}}
              >
                <HomeNewsFieldArray />
              </CardTitle>
            </Grid>
          </Grid>

          <Stack alignItems="flex-end" mt={2}>
            <FormikSubmit>Enregistrer</FormikSubmit>
          </Stack>
        </SimpleForm>
      </Create>
    </CatalogueLayout>
  )
}

export default HomeEdit;