import React from 'react';
import { SvgIcon } from '@mui/material';

const DashboardIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 283.46 283.46" {...props}>
      <path d="m96.28,80.47l-12.3-12.3c-1.66-1.66-4.35-1.66-6.01,0l-12.3,12.3c-1.66,1.66-1.66,4.35,0,6.01h0c1.66,1.66,4.35,1.66,6.01,0l5.34-5.34v96h7.91v-96l5.34,5.34c1.66,1.66,4.35,1.66,6.01,0h0c1.66-1.66,1.66-4.35,0-6.01Z" fill="#00965d"/>
      <path d="m234.83,214.4l12.3-12.3c1.66-1.66,1.66-4.35,0-6.01l-12.3-12.3c-1.66-1.66-4.35-1.66-6.01,0h0c-1.66,1.66-1.66,4.35,0,6.01l5.34,5.34H101.27v7.91h132.88l-5.34,5.34c-1.66,1.66-1.66,4.35,0,6.01h0c1.66,1.66,4.35,1.66,6.01,0Z" fill="#00965d"/>
      <path d="m216.31,109.63c-.04-2.35-1.97-4.22-4.32-4.19l-17.23.26c-2.04.03-3.9,1.43-4.27,3.44-.51,2.74,1.61,5.11,4.24,5.07l7.55-.11s0,0,0,0l-25.48,25.62s0,0,0,0l-36.99-15.11c-.41-.17-1.7-.23-2.27.75l-29.51,39.32c-.86,1.15-.63,2.78.52,3.65l1.41,1.05c1.51,1.14,3.66.83,4.8-.68l26.44-35.23s0,0,0,0l36.71,15.12c.53.22,1.15.1,1.56-.31l28.51-28.67s0,0,0,0l.11,7.48c.04,2.34,1.98,4.25,4.32,4.25h0c2.34,0,4.22-1.91,4.19-4.25l-.26-17.46Z" fill="#00965d"/>
      <path d="m80.97,215.48c-9.04,0-16.39-7.35-16.39-16.39s7.35-16.39,16.39-16.39,16.39,7.35,16.39,16.39-7.35,16.39-16.39,16.39Zm0-27.11c-5.91,0-10.72,4.81-10.72,10.72s4.81,10.72,10.72,10.72,10.72-4.81,10.72-10.72-4.81-10.72-10.72-10.72Z" fill="#00965d"/>
    </SvgIcon>
  )
}

export default DashboardIcon;
