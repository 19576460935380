import React from 'react'
import { Box, Stack, Typography, Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom';
import Head from '../components/shared/Head';
import PageContainer from '../components/shared/PageContainer';
import ArvalLogo from '../assets/img/arvalLogo.png';

const AuthLayout = ({ title, description, subDescription, links, children }) => (
  <Box
    sx={{
      background: "url(/img/Image_log_arval_cat.jpg) no-repeat",
      backgroundSize: 'cover',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <Head title={title} description={description} />
    <img style={{ marginBottom: 15 }} src={ArvalLogo} width="200px" alt="arval-logo" />
    <PageContainer title={title} sx={{ border: 'none', width: { md: 800 }, mb: 2, borderRadius: 3, margin: 2 }}>
      <Stack textAlign="center" alignItems="center" justifyContent="center">
        {description &&
          <Typography variant="h6" component="span" sx={{ marginBottom: subDescription ? "10px!important" : "20px!important" }}>{description}</Typography>
        }
        {subDescription &&
          <Typography width={{sx: "100%", sm: "80%", md: "50%" }} fontSize={14} component="span">{subDescription}</Typography>
        }
        <Stack spacing={5} textAlign="center" alignItems="center" justifyContent="center">
          {children}
          
          {links && (
            <Stack direction={{ xs: "column", md: "row"}} spacing={{ xs: 2, md: 5}}>
              {links.map((link, key) => (
                <MuiLink key={`AuthLayout-Links-${key}`} component={Link} to={link.to} color="inherit">
                  <Typography>{link.label}</Typography>
                </MuiLink>
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
    </PageContainer>
  </Box>
)

export default AuthLayout