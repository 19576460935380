import React from 'react';
import { Button, Link, Typography } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileIcon from '../../assets/img/folder.png'

interface UploadButtonProps {
  dragActive?: boolean;
  value?: File;
}

const UploadButton = ({ value, dragActive }: UploadButtonProps) => (
  <Button
    sx={{
      backgroundColor: dragActive && "#fff",
      opacity: dragActive && 0.5,
      py: 5,
      px: {xs: 1, md: 15},
      flexDirection: "column",
      textAlign: "center",
      border: `1px dashed`,
      borderRadius: 3,
      textTransform: "none",
      '& svg': {
        fontSize: '55px !important',
      }
    }}
    component="span"
    color="inherit"
  >
    <img src={FileIcon} />
    <Typography fontWeight={400} gutterBottom><span style={{ textDecoration: "underline" }}>Cliquez pour choisir un fichier</span> ou glissez-le dans la fenêtre.</Typography>
    <Typography color="textSecondary">Maximum 5Go au format .xlsx</Typography>
    <Typography color="primary">{value?.name}</Typography>
  </Button>
)

export default UploadButton;