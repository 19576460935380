import React from 'react';
import { SvgIcon } from '@mui/material';

const StandardIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <rect width="20" height="20" rx="10" />
      <path d="M8.23609 13.8429L4.66108 10.3563C4.44631 10.1468 4.44631 9.80718 4.66108 9.59769L5.43888 8.8391C5.65366 8.62961 6.00193 8.62961 6.21671 8.8391L8.625 11.1878L13.7833 6.1571C13.9981 5.94763 14.3463 5.94763 14.5611 6.1571L15.3389 6.91569C15.5537 7.12516 15.5537 7.46479 15.3389 7.67428L9.01391 13.8429C8.79911 14.0524 8.45087 14.0524 8.23609 13.8429Z" fill="white"/>
    </SvgIcon>
  )
}

export default StandardIcon;