import React from 'react';
import { Field } from 'formik';
import { Stack, Typography, Radio, FormControlLabel } from '@mui/material';
import UploadButton from 'components/shared/UploadButton';
import { FormikRadioGroup, FormikUploadInput, FormikSubmit } from '@agencelafusee/mui-admin-modules';
import { object, mixed} from 'yup';
import axios from '../../api/axios';
import Create from 'components/shared/Create';
import { queryClient } from '../../App.js'
import SimpleForm from 'components/shared/SimpleForm';

const schema = object().shape({
  file: mixed()
    .nullable()
    .required('VALIDATION_FIELD_REQUIRED')
    .test(
      'is-big-file',
      'VALIDATION_FIELD_FILE_WRONG_TYPE',
      (file: any) => file && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)
    ),
})

interface FileCreateProps {
  onSuccess?: (data: any, variables: any, context: any) => void | Promise<any>
}

const FileCreate = ({ onSuccess }: FileCreateProps) => {
  const handleSubmit = (mutate, values) => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('type', values.type);
    mutate(formData);
  }

  const handleSuccess = (data: any, variables: any, context: any) => {
    queryClient.removeQueries({ queryKey: 'files', exact: false });
    onSuccess?.(data, variables, context);
  }

  return (
    <Create
      onSubmit={handleSubmit}
      query={(data) => axios.post('/import', data)}
      initialValues={{ file: null, type: 'catalogue' }}
      validationSchema={schema}
      onSuccess={handleSuccess}
    >
      <SimpleForm withAlert successMessage="Le fichier a bien été importé.">
        <Stack spacing={3} alignItems="center">
          <Typography
            color="primary"
            variant="h5"
          >
            Sélectionnez un fichier à charger
          </Typography>
          <Field
            droppable
            component={FormikUploadInput}
            name="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
            inputRender={({ value, dragActive }) => (
              <UploadButton value={value} dragActive={dragActive} />
            )}
          />
          <FormikSubmit>Charger le fichier</FormikSubmit>
        </Stack>
      </SimpleForm>
    </Create>
  )
}

export default FileCreate;