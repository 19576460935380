import React from 'react';
import { SvgIcon } from '@mui/material';

const LogoutIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 283.46 283.46" {...props}>
      <path d="m153.79,83.54v8.69c24.9,5.64,43.55,27.93,43.55,54.51,0,30.83-25.08,55.9-55.9,55.9s-55.9-25.08-55.9-55.9c0-26.63,18.72-48.95,43.69-54.54v-8.69c-29.69,5.73-52.19,31.89-52.19,63.23,0,35.51,28.89,64.41,64.41,64.41s64.41-28.89,64.41-64.41c0-31.29-22.43-57.43-52.06-63.21Z" fill="#00965d"/>
      <rect x="137.48" y="71.81" width="8.06" height="60.39" rx="4.03" ry="4.03" fill="#00965d"/>
    </SvgIcon>
  )
}

export default LogoutIcon;
