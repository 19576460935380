import React from 'react'
import { useParams } from 'react-router-dom';
import { CatalogueLayout } from '../layouts'
import { CatalogService } from '../services/CatalogService';
import { useNavigate } from 'react-router';
import { FeatureAccordionGrid, ServiceFilterList, FeatureSyncFilterButton, SegmentInfoSelect, SegmentFilterSelect } from '../components/catalog';
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography } from '@mui/material';
import PageContainer from '../components/shared/PageContainer';
import { data } from '../api/data';
import { useSearchParams } from 'react-router-dom/dist';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { useQuery } from 'react-query';
import axios from '../api/axios';

const Header = ({ expanded, onExpanded }) => {
  return (
    <Stack
      sx={{
        '& button': {
          borderRadius: 0,
          height: 28
        }
      }}
      flexDirection="row"
    >
      <FeatureSyncFilterButton size="medium" variant="contained" color="warning" fullWidth />
      <Button onClick={onExpanded} variant="contained" size="medium" color="success" fullWidth>
        {expanded ? <ExpandLessRoundedIcon fontSize="large" /> : <ExpandMoreRoundedIcon fontSize="large" />}
      </Button>
      <SegmentInfoSelect size="medium" variant="contained" color="info" fullWidth />
    </Stack>
  );
}

const Page = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [expandedAll, setExpandedAll] = React.useState(false);
  const { data } = useQuery('catalogs', () => axios.get('/catalogue').then(res => res.data?.data));
  const { product, service, component } = CatalogService.getData(
    data?.[0].products,
    params.product,
    params.service,
    params.component
  );

  React.useEffect(() => {
    if (service && !params.component) {
      const slug = service.components[0].slug;

      if (slug) {
        navigate(`/catalog/${params.product}/services/${params.service}/components/${slug}`)
      }
    }
  }, [params, navigate, service])

  if (params.component && !component) {
    return (
      <div>
        404
      </div>
    )
  }

  return (
    <CatalogueLayout
      title={service?.name}
      action={
        <SegmentFilterSelect style={{ position: { md: 'absolute' } }} sx={{ borderRadius: 3, textTransform: 'initial' }} />
      }
    >
      <PageContainer
        title={service?.name}
        header={<Header expanded={expandedAll} onExpanded={() => setExpandedAll(!expandedAll)} />}
        sx={{
          borderRadius: 5,
        }}
      >
        <ServiceFilterList services={service?.components} />
        <Stack spacing={1}>
          <Accordion defaultExpanded>
            <AccordionSummary 
              sx={{
                backgroundColor: "secondary.main",
                color: "white"
              }}
            >
              <Typography variant="h4">{`Focus ${component?.name}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography 
                fontWeight={300}
                dangerouslySetInnerHTML={{ __html: component?.focus }}
              />
            </AccordionDetails>
          </Accordion>
          <FeatureAccordionGrid expandedAll={expandedAll} features={component?.features.filter(feature => {
            if (searchParams.get('sync')) {
              if (feature.sync === Boolean(searchParams.get('sync'))) {
                return feature;
              }
            } else {
              return feature;
            }
          })} />
        </Stack>
      </PageContainer>
    </CatalogueLayout>
  );
}

export default Page;