import React from 'react';
import { Box, Divider, Paper, Stack, Typography, styled } from '@mui/material';

const BoxStyled = styled(Box)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  padding: "20px 40px 9px 40px",
}));

const PageContainer = ({ sx, color = 'primary', title, header, children, ...otherProps }: any) => (
  <Paper sx={{ overflow: 'hidden', ...sx }} variant="outlined" {...otherProps}>
    <BoxStyled>
      <Typography
        variant="h3"
      >
        {title}
      </Typography>
    </BoxStyled>
    {header}
    <Stack marginTop={2} spacing={4} padding={4} divider={<Divider color="primary" />}>
      {children}
    </Stack>
  </Paper>
);

export default PageContainer;