import React from 'react';
import { Helmet } from 'react-helmet';

interface HeadProps {
  title: string;
  description?: string;
  language: string;
  card: string;
}

const Head = ({
  title,
  description,
  language,
  card = '/static/social-previews/default-preview.png',
}: HeadProps) => (
  <Helmet htmlAttributes={{ lang: language }}>
    <title>{`${title} - Catalogue Arval`}</title>
    <meta name="description" content={description} />
    {/* Twitter */}
    <meta name="twitter:card" content="summary" />
    {/* https://twitter.com/MUI_hq */}
    <meta name="twitter:site" content="@Arval_France" />
    {/* #major-version-switch */}
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={card} />
    {/* Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    {/* #major-version-switch */}
    {/* <meta property="og:url" content={`${HOST}${router.asPath}`} /> */}
    <meta property="og:description" content={description} />
    <meta property="og:image" content={card} />
    <meta property="og:ttl" content="604800" />
    {/* Algolia */}
    <meta name="docsearch:language" content={language} />
    {/* #major-version-switch */}
    <meta name="docsearch:version" content="master" />
  </Helmet>
);

export default Head;
