import React from 'react'
import { IconButton, Paper, Box } from '@mui/material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CatalogSearchList from './SearchList';
import { useParams } from 'react-router';

const CatalogSearchMenu = ({ open, onClose }) => {
  const params = useParams();

  React.useEffect(() => {
    if (open) {
      window.scrollTo({ top: 0 });
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [open]);

  React.useEffect(() => {
    if (open) {
      onClose();
    }
  }, [params]);

  return (
    <Paper
      sx={{
        display: open ? 'flex' : 'none',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'absolute',
        height: '100vh',
        width: '100%',
        zIndex: 2,
      }}
    >
      <Box pr={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton size="large" onClick={onClose}>
          <ClearRoundedIcon color="primary" fontSize="large" />
        </IconButton>
      </Box>
      <CatalogSearchList pb={5} pr={5} />
    </Paper>
  )
}

export default CatalogSearchMenu;