import React from 'react';
import { SvgIcon } from '@mui/material';

const ProfilIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 283.46 283.46" {...props}>
      <path d="m123.41,172.43h0c-.39-1.52-1.93-2.43-3.45-2.05l-47.75,12.14.02.09-17.37,22.98c-.94,1.25-.7,3.03.55,3.97h0c1.25.94,3.03.7,3.97-.55l16.27-21.52,45.71-11.62c1.52-.39,2.43-1.93,2.05-3.45Z" fill="#00965d"/>
      <path d="m159.53,172.43h0c.39-1.52,1.93-2.43,3.45-2.05l47.75,12.14-.02.09,17.37,22.98c.94,1.25.7,3.03-.55,3.97h0c-1.25.94-3.03.7-3.97-.55l-16.27-21.52-45.71-11.62c-1.52-.39-2.43-1.93-2.05-3.45Z" fill="#00965d"/>
      <path d="m129.95,174.02l4.72-2.81c.66-.4,1.42-.6,2.2-.6h10.14c.85,0,1.68.25,2.38.72l3.89,2.59c.73.49,1.17,1.31,1.17,2.19v1.6c0,.71-.18,1.41-.51,2.04l-6.48,12.01,4.56,25.48c.47,2.63-1.55,5.05-4.23,5.05h-12.68c-2.7,0-4.73-2.47-4.22-5.12l4.97-25.41s0-.01,0-.02l-6.52-12.03c-.34-.63-.52-1.33-.52-2.05v-1.64c0-.83.44-1.59,1.14-2.01Z" fill="#00965d"/>
      <path d="m140.16,166.36c-12.95,0-25.23-9.43-34.72-26.61-10.87-1.83-19.38-14.46-19.64-24.92-.26-10.17,4.23-18.68,11.59-22.37-1.85-15.62-.29-26.34,4.66-31.89,2.46-2.76,5.08-3.42,6.33-3.58,2.42-1.02,5.05-2.81,8.08-4.87,7.59-5.16,17.02-11.58,30.36-10.58,30.96,2.32,37.66,22.35,37.66,27.18,0,4.1.37,15.77.59,22.74l.02.76c3.93,2.03,11.74,7.89,11.74,22.27,0,15.19-12.12,21.4-19.68,22.87-10.06,18.67-23.12,28.96-36.87,29.01h-.11Zm-31.13-103.75c-.29.04-1.59.29-2.97,1.97-2.16,2.63-5.39,9.92-2.79,29.26l.31,2.29-2.18.76c-6.25,2.19-10.16,9.17-9.94,17.79.21,8.35,7.62,19.03,15.96,19.6l1.57.11.74,1.39c8.57,16.11,19.42,24.93,30.51,24.92,11.68-.05,23.54-9.97,32.54-27.23l.69-1.32,1.48-.18c.65-.08,16.19-2.25,16.2-17.48,0-13.51-8.17-17.15-9.81-17.74l-1.81-.65-.14-4.45c-.24-7.46-.59-18.73-.59-22.92,0-3.14-5.63-19.52-32.41-21.53-11.33-.85-19.53,4.71-26.75,9.62-3.32,2.26-6.46,4.39-9.57,5.61l-.63.25-.41-.05Z" fill="#00965d"/>
      <path d="m170.02,97.77c-7.7-3.42-12.84-8.91-17.05-13.58-12.12,10.01-30.61,13.25-40.79,13.01-1.68-.04-2.96-1.51-2.76-3.17h0c.17-1.44,1.41-2.53,2.86-2.49,9.56.25,28.25-3.09,38.94-13.38l.86-.83c.68-.65,1.76-.62,2.39.08l.81.88c.43.48.88.97,1.33,1.48,3.93,4.37,8.72,9.69,15.71,12.82,1.36.61,2.05,2.12,1.57,3.53h0c-.55,1.59-2.34,2.33-3.87,1.65Z" fill="#00965d"/>
    </SvgIcon>
  )
}

export default ProfilIcon;
