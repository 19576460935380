import React from 'react'
import CascadingContext from './CascadingContext'
import { MenuItem } from '@mui/material';

export default function CascadingMenuItem({ onClick, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext)
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu')
  const handleClick = React.useCallback(
    (event) => {
      rootPopupState.close(event)
      if (onClick) onClick(event)
    },
    [rootPopupState, onClick]
  )

  return <MenuItem sx={{ fontSize: 15 }} {...props} onClick={handleClick} />
}