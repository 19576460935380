import React from 'react'
import PageContainer from '../components/shared/PageContainer';
import { CatalogueLayout } from '../layouts';
import { Box, Pagination } from '@mui/material';
import FileCreate from '../components/file/FileCreate';
import FileList from '../components/file/FileList';
import { QueryListProvider } from '@agencelafusee/react-query-list';
import { useQuery } from 'react-query';
import Axios from '../api/axios';

const Page = () => {
  const [count, setCount] = React.useState(25);
  const [params, setParams] = React.useState({ page: 1, per_page: 4 });
  const { data, isLoading, refetch } = useQuery(['files', { ...params }], () =>
    Axios.get('/catalogue/imports', { params }).then(res => res.data)
  );

  React.useEffect(() => {
    if (data) {
      setCount(data.count_pages);
    }
  }, [data]);

  const handleQueryFilterChange = (filterValues) => {
    setParams(filterValues);
  }

  const handleSuccess = () => {
    refetch();
  }

  return (
    <CatalogueLayout title="Mise à jour du fichier">
      <PageContainer title="Mise à jour du fichier" sx={{ borderRadius: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FileCreate onSuccess={handleSuccess} />
        </Box>
        <QueryListProvider
          enableReinitialize
          data={data}
          onQueryFilterChange={handleQueryFilterChange}
          loading={isLoading}
          error={false}
          filters={params}
        >
          {(({ items }) => (
            <Box>
              <FileList items={items} isLoading={isLoading} perPage={params.per_page} refetch={refetch} />
              <Pagination
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                color="primary"
                count={count}
                page={params.page}
                onChange={(_, page) => {
                  setParams({...params, page })
                }}
              />
            </Box>
          ))}
        </QueryListProvider>
      </PageContainer>
    </CatalogueLayout>
  );
}

export default Page;