import React from 'react';
import { SvgIcon } from '@mui/material';

const OptionIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <rect width="20" height="20" rx="10" />
      <path d="M10 13.0222L12.4933 14.8956C12.8885 15.1991 13.4257 14.791 13.2737 14.3095L12.321 11.2848L14.7838 9.47422C15.1892 9.18117 14.9865 8.52182 14.4899 8.52182H11.4696L10.4865 5.36108C10.3345 4.87964 9.67567 4.87964 9.52364 5.36108L8.53039 8.52182H5.51009C5.01347 8.52182 4.81076 9.18117 5.21617 9.47422L7.67903 11.2848L6.72632 14.3095C6.57429 14.791 7.11146 15.1887 7.50673 14.8956L10 13.0222Z" fill="white"/>
    </SvgIcon>
  )
}

export default OptionIcon;