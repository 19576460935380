import React from 'react';
import { Formik, FormikValues, FormikConfig, FormikHelpers } from 'formik';
import { MutateFunction, useMutation, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom/dist';

export const FormContext = React.createContext<UseMutationResult<
  any,
  unknown,
  void,
  unknown
> | null>(null);

export type FormikCreateProps<Values extends FormikValues = FormikValues> =
  Omit<FormikConfig<Values>, 'onSubmit'> & {
    error?: {
      statusText: string | null;
      status: number | null;
    } | null;
    query: MutateFunction<any, any>;
    onSuccess?: (
      data: any,
      variables: any,
      context: any,
    ) => Promise<any> | void;
    onError?: (error: any, variables: any, context: any) => Promise<any> | void;
    redirectAfterSuccess?: string;
    disableScrollToTop?: boolean;
    onSubmit: (
      mutation: any,
      values: FormikValues,
      formikHelpers: FormikHelpers<any>,
    ) => void;
  };

const Create = ({
  error,
  query,
  onSubmit,
  onSuccess,
  onError,
  disableScrollToTop,
  redirectAfterSuccess,
  ...otherProps
}: FormikCreateProps) => {
  const formikRef = React.useRef<FormikHelpers<any>>();
  const navigate = useNavigate();
  const mutation = useMutation(
    async (data) => {
      const { data: response } = await query(data);
      return response.data;
    },
    {
      onSuccess: (data, variables, context) => {
        if (onSuccess) {
          onSuccess(data, variables, context);
        }

        if (redirectAfterSuccess) {
          navigate(redirectAfterSuccess);
        }

        formikRef.current?.setSubmitting(false);
      },
      onError: (data, variables, context) => {
        if (onError) {
          onError(data, variables, context);
        }

        formikRef.current?.setSubmitting(false);
      },
    },
  );

  const handleSubmit = (values: FormikValues, ctx: FormikHelpers<any>) => {
    onSubmit(mutation.mutate, values, ctx);
    formikRef.current = ctx;
  };

  return (
    <FormContext.Provider value={mutation}>
      <Formik onSubmit={handleSubmit} {...otherProps} />
    </FormContext.Provider>
  );
};

export default Create;
