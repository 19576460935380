import React from 'react';
import { Alert, AlertProps } from '@mui/material';

interface ValidationObject {
  message: string;
  variables: { field: string };
}

interface AlertErrorProps extends AlertProps {
  message?: string;
  validations?: ValidationObject[];
  scrollIntoView?: boolean;
}

const AlertError = ({
  validations,
  scrollIntoView,
  message,
  ...otherProps
}: AlertErrorProps) => {
  const alertRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (alertRef.current && scrollIntoView) {
      alertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [scrollIntoView]);

  return (
    <Alert ref={alertRef} severity="error" {...otherProps}>
      {message}
      {validations && (
        <ul>
          {validations.map((validation, key: number) => (
            <li key={`AlertError-ValidationList-${key}`}>
              {validation.message}
            </li>
          ))}
        </ul>
      )}
    </Alert>
  );
};

export default AlertError;
