import React from 'react';
import { Button } from '@mui/material';
import { bindFocus, bindTrigger } from 'material-ui-popup-state';

const ButtonPopover = React.forwardRef(
  (
    { popupState, icon = 'chevron-down', iconSize = 16, ...otherProps },
    ref,
  ) => (
    <Button
      ref={ref}
      sx={{
        fontSize: 12,
        borderRadius: '10px!important',
        justifyContent: 'flex-start',
        '&:hover': {
          backgroundColor: 'primary.main',
          color: 'white'
        },
        '&:focus': {
          backgroundColor: 'primary.main',
          color: 'white',
          width: '100%',
          borderTopRightRadius: '0!important',
          borderBottomRightRadius: '0!important',
        },
      }}
      variant="text"
      color="primary"
      {...bindTrigger(popupState)}
      {...bindFocus(popupState)}
      {...otherProps}
    />
  ),
);

export default ButtonPopover;