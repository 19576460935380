import React from 'react';
import { useQuery } from 'react-query';
import axios from '../api/axios';
import PageContainer from '../components/shared/PageContainer';
import { CatalogueLayout } from '../layouts';
import LoginHistoryChart from '../components/login-history/LoginHistoryChart';

const Dashboard = () => {
  const { data } = useQuery('login_history', () => axios.get('/stats/login_history').then(res => res.data?.data));

  return (
    <CatalogueLayout title="Dashboard">
      <PageContainer title="Dashboard" sx={{ borderRadius: 3 }}>
        <LoginHistoryChart data={data} />
      </PageContainer>
    </CatalogueLayout>
  )
}

export default Dashboard;