import { Box, Typography, Card, Stack, styled } from '@mui/material'
import React from 'react'
import { CatalogueLayout } from '../layouts'
import ErrorImg from '../assets/img/error.png';

const TypographyStyled = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent"
}));

const NotFoundPage = () => {
  return (
    <CatalogueLayout
      title="Erreur 404"
      errorPage={true}
    >
      <Card 
        sx={{ 
          height: 'calc(100vh - 120px)', 
          display: "flex", 
          flexDirection: "column", 
          justifyContent: "center", 
          alignItems: "center" 
        }}
      >
        <Stack alignItems="center" textAlign="center" spacing={2}>
          <img src={ErrorImg} width={49} alt="Error" />
          <TypographyStyled
            component="h2" 
            variant="h3"
            fontSize={46}
          >
            Erreur
          </TypographyStyled>
          <Typography 
            component="h2" 
            variant="h3" 
            sx={{ color: "#616161" }}
            fontSize={30}
          >
            La page que vous recherchez est introuvable
          </Typography>
        </Stack>
      </Card>
    </CatalogueLayout>
  )
}

export default NotFoundPage