import React from 'react';
import { Alert, AlertProps } from '@mui/material';

interface AlertSuccessProps extends AlertProps {
  message: string;
  scrollIntoView?: boolean;
}

const AlertSuccess = ({
  message,
  scrollIntoView,
  ...otherProps
}: AlertSuccessProps) => {
  const alertRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (alertRef.current && scrollIntoView) {
      alertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [scrollIntoView]);

  return (
    <Alert ref={alertRef} severity="success" {...otherProps}>
      {message}
    </Alert>
  );
};

export default AlertSuccess;
