import React from 'react';
import { SvgIcon } from '@mui/material';

const DefenseIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <rect width="20" height="20" rx="10"/>
      <path d="M5.83333 14.1667C5.3731 13.7064 5.3731 12.9602 5.83333 12.5L8.33333 10L5.83333 7.5C5.3731 7.03976 5.3731 6.29357 5.83333 5.83333C6.29357 5.3731 7.03976 5.3731 7.5 5.83333L10 8.33333L12.5 5.83333C12.9602 5.3731 13.7064 5.3731 14.1667 5.83333C14.6269 6.29357 14.6269 7.03976 14.1667 7.5L11.6667 10L14.1667 12.5C14.6269 12.9602 14.6269 13.7064 14.1667 14.1667C13.7064 14.6269 12.9602 14.6269 12.5 14.1667L10 11.6667L7.5 14.1667C7.03976 14.6269 6.29357 14.6269 5.83333 14.1667Z" fill="white"/>
    </SvgIcon>
  )
}

export default DefenseIcon;
