import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom/dist';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { SegmentStatusIcon } from '../segment';

const FeatureAccordion = (
  {
    expandedAll = false,
    title,
    sync,
    subjects,
  }
) => {
  const [expanded, setExpanded] = React.useState(expandedAll);
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (expandedAll !== expanded) {
      setExpanded(expandedAll);
    }
  }, [expandedAll])

  const getSegmentSelected = (segments) => {
    const segment = searchParams.get('segment') || 'PLLD';
    const status = segments?.find(item => item.segment.name === segment)?.status || segment;
    return <SegmentStatusIcon status={status} />
  }

  const handleChange = () => {
    setExpanded(!expanded);
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary sx={{ minHeight: 65 }}>
        <Typography component="span" variant="h6" fontWeight={400}>
          <Stack spacing={1} direction="row" alignItems="center">
            {sync && <SyncAltIcon sx={{ color: "common.white", bgcolor: 'warning.main', borderRadius: 1 }} />}
            <div>{title}</div>
          </Stack>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {subjects?.map((subject, index) => (
          <List key={`subject-${index}`}>
            <ListItem secondaryAction={<Typography fontWeight={400}>{JSON.parse(localStorage.getItem('segment'))?.label || 'PLLD'}</Typography>}>
              <ListItemText sx={{ "& .MuiListItemText-primary": { fontWeight: 400 } }} primary={subject?.name} />
            </ListItem>
            {subject.items?.map((item, subIndex) => (
              <ListItem 
                key={`item-${subIndex}`}
                sx={{ 
                  textAlign: 'left', 
                  alignItems: 'flex-start',
                  "& .MuiListItemSecondaryAction-root": {
                    top: 'initial',
                    transform: 'initial',
                    marginTop: '2px'
                  } 
                }} 
                secondaryAction={getSegmentSelected(item.segments)}
              >
                <ListItemText sx={{ marginRight: 2 }} primaryTypographyProps={{ fontWeight: 300 }} primary={item.name} />
              </ListItem>
            ))}
          </List>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default FeatureAccordion;