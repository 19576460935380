import React from 'react';
import ModalBase from 'components/shared/ModalBase';
import { Stack, Typography, Grid, styled, Button, Box } from '@mui/material'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { 
  SyncAltRounded as SyncAltRoundedIcon,  
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  Info as InfoIcon
} from '@mui/icons-material';

const ButtonPreviousStyled = styled(Button)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  borderColor: theme.palette.primary.main,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textTransform: 'capitalize',
  fontWeight: '700!important'
}));

const ButtonNextStyled = styled(Button)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  textTransform: 'capitalize',
  fontWeight: '700!important'
}));

const SliderStyle = styled(Slider)(({ theme }) => ({
  width: '100%',
  maxWidth: '100vw',
  '& .slick-slide > div': {
    padding: 15
  },
  '& .slick-prev:hover, .slick-next:hover': {
    color: theme.palette.primary.main,
  },
  '& .slick-dots': {
    bottom: '-40px',
    '& li': {
      margin: 'initial'
    }
  },
  '& .slick-dots li button:before': {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    opacity: "initial",
    width: 5,
    height: 5,
    fontSize: 'unset',
    borderRadius: 5,
    color: 'initial'
  },
  '& .slick-dots li.slick-active button:before': {
    backgroundColor: theme.palette.primary.main,
    opacity: "initial"
  },
}))

const BoxIcon = ({ color, radius, icon }) => {
  return (
    <div>
      <Box
      background="warning"
      sx={{
        backgroundColor: color,
        color: "white",
        width: 20,
        height: 20,
        borderRadius: radius
      }}
      >
        {icon}
      </Box>
    </div>
  )
}

const data = [
  {
    id: 1,
    title: "BIENVENUE !",
    intro: "Ce tutoriel du Catalogue  des Produits et Services Arval va vous montrer en quelques étapes les éléments clés à prendre en main pour son utilisation.",
    url: "/img/tutorial/1_Home.png"
  },
  {
    id: 2,
    title: "Choisir votre catalogue",
    intro: "Accessible tout au long de votre parcours sur la plateforme, cette liste déroulante vous permet de naviguer facilement au sein des différents segments commerciaux.",
    url: "/img/tutorial/2_segment.png"
  },
  {
    id: 3,
    title: "Liste des produits & services",
    intro: "Vous retrouverez dans cette liste l’ensemble des produits et prestations à votre disposition. Cette colonne reste accessible à tout moment au cours de votre navigation.",
    url: "/img/tutorial/3_menu.png"
  },
  {
    id: 4,
    title: "Fonction recherche",
    intro: "La fonction Recherche en bas de l’écran est dynamique et vous permet sur la base d’un mot clé, d’afficher les entrées issues du catalogue au gré de l’avancée de votre saisie.",
    url: "/img/tutorial/4_Home_recherche.png"
  },
  {
    id: 5,
    title: "Les icônes de tri",
    intro: "Ces icônes vous permettront d’accéder facilement à l’information que vous recherchez.",
    icon: <BoxIcon color="#FA9454" radius={1} icon={<SyncAltRoundedIcon fontSize="small" />} />,
    explanation: "Ces flèches orange vous permettent de ne mettre en avant que les catégories qui ont subit un changement depuis la mise à jour précédente.",
    url: "/img/tutorial/5_changements.png"
  },
  {
    id: 6,
    title: "Les icônes de tri",
    icon: <BoxIcon color="#62C797" radius={1} icon={<ExpandMoreRoundedIcon fontSize="small" />} />,
    explanation: "La flèche verte vous permet de déployer toutes les cartes afin de pouvoir les lire dans leur globalité.",
    url: "/img/tutorial/6_deployer.png"
  },
  {
    id: 7,
    title: "Les icônes de tri",
    icon: <BoxIcon radius={4} icon={<InfoIcon fontSize="small" color="info" />} />,
    explanation: "Le bouton infos vous permet un accès constant aux explications des pictogrammes utilisés.",
    url: "/img/tutorial/7_Infos.png"
  },
  {
    id: 8,
    title: "Votre catalogue partout",
    intro: "Retrouvez également votre catalogue sur mobile et tablette. ",
    url: "/img/tutorial/8_mobiletab.png"
  },
]

const TutorialModal = ({control, ...props}) => {
  const [open, setOpen] = React.useState(false);
  const customSlider = React.createRef();

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  var settings = {
    dots: true,
    arrow: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      },
    ]
  };

  const handlePrevious = () => {
    customSlider.current.slickPrev();
  }

  const handleNext = () => {
    customSlider.current.slickNext();
  }

  return (
    <>
      {React.cloneElement(control, { onClick: handleOpen })}
      <ModalBase 
        sx={{ 
          width: { xs: "95%", lg: 900 }, 
          height: { md: 'auto', lg: 650 } 
        }} 
        open={open} 
        onClose={handleClose} 
        title="Tutoriel" 
        {...props}
      >
        <Stack>
          <SliderStyle {...settings} ref={customSlider}>
            {data.map((el) => (
              <div 
                key={el.id}
              >
                <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={{ md: "space-around" }} spacing={3}>
                  <Stack width={{xs: "auto", md: "25%" }} spacing={2}>
                    <Typography component="h2" variant='h3' color="primary" fontSize={30}>{el.title}</Typography>
                    <Typography sx={{ lineHeight: 1.2 }} variant="body1" fontWeight={400}>
                      {el.intro}
                    </Typography>
                    {el.icon && (
                      el.icon
                    )}
                    {el.explanation && (
                      <Typography sx={{ lineHeight: 1.2 }} variant="body1" fontWeight={400}>
                        {el.explanation}
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    sx={{
                      borderRadius: 3,
                      // width: '100%',
                      height: { md: 400 },
                      margin: '0 auto'
                    }}
                  >
                    <Box 
                      component="img"
                      alt=''
                      src={el.url} 
                      sx={{ 
                        width: '100%', 
                        height: '100%', 
                        objectFit: "contain",
                        borderRadius: 3,
                        boxShadow: "4px 4px 15px rgba(0, 0, 0, 0.15)",
                      }} 
                    />
                  </Stack>
                </Stack>
              </div>
            ))}
          </SliderStyle>
          <Stack direction="row" justifyContent={{xs : "space-evenly", md: "space-between"}} sx={{ marginTop: { xs: 6, md: 0 }}}>
            <ButtonPreviousStyled variant="outlined" onClick={handlePrevious}>
              Précédent
            </ButtonPreviousStyled>
            <ButtonNextStyled variant="contained" onClick={handleNext}>
              Suivant
            </ButtonNextStyled>
          </Stack>
        </Stack>
      </ModalBase>
    </>
  )
}

export default TutorialModal;