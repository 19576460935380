import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import axios from '../../api/axios';
import { useMutation } from 'react-query';
import AuthLayout from '../../layouts/AuthLayout';

const Page = () => {
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { search } = useLocation();
  const { mutate } = useMutation(
    async () => {
      const searchParams = new URLSearchParams(search);
      const response = await axios.post('/users/verify', {
        registrationToken: searchParams.get("token"),
        email: searchParams.get("email"),
      });

      return response.data;
    },
    {
      onSuccess: () => {
        setSuccess(true);
      },
      onError: () => {
        setError(true);
      }
    }
  );

  React.useEffect(() => {
    mutate();
  }, []);

  return (
    <AuthLayout
      title="Vérification du compte"
    >
      {success && (
        <>
          <Typography variant="body1">Votre compte a ete verifier avec success</Typography>
          <Button component={Link} to="/login" variant='contained'>Se connecter</Button>
        </>
      )}
      {error && (
        <>
          <Typography variant="body1">Le token n'existe pas ou a exipiré</Typography>
          {/* <Button component={Link} to="/recovery/request-token">Me renvoyer un token</Button> */}
        </>
      )}
    </AuthLayout>
  );
}

export default Page;
