import React from 'react';
import { QueryFunction, QueryKey, useQuery } from 'react-query';

const QueryContext = React.createContext<{
  data?: any;
  isLoading?: boolean;
  refetch?: any;
}>({});

interface QueryProps {
  children: any;
  queryKey: QueryKey;
  queryFn: QueryFunction<any, QueryKey>;
}

const Query = ({ queryKey, queryFn, children }: QueryProps) => {
  const { data, isLoading, refetch } = useQuery(queryKey, queryFn);

  return (
    <QueryContext.Provider value={{ data, isLoading, refetch }}>
      <QueryContext.Consumer>{children}</QueryContext.Consumer>
    </QueryContext.Provider>
  );
};

export default Query;