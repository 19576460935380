import React from 'react';
import { Stack } from '@mui/material';
import { CatalogService } from '../../services/CatalogService';
import CatalogSearchInput from './SearchInput';
import CatalogSearchItemList from './SearchItemList';
import axios from '../../api/axios';
import { useQuery } from 'react-query';

const CatalogSearchList = (props) => {
  const [searchText, setSearchText] = React.useState('');
  const { data } = useQuery('catalogs', () => axios.get('/catalogue').then(res => res.data?.data));
  const items = CatalogService.search(data?.[0]?.products || [], searchText);

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
  }

  return (
    <Stack spacing={4} {...props}>
      <Stack sx={{ overflowY: 'scroll', maxHeight: '75vh', px: 2 }} spacing={4}>
        {items.map((item, key) => (
          <CatalogSearchItemList key={`CatalogSearchList-ItemList-${key}`} searchText={searchText} item={item} />  
        ))}
      </Stack>
      <CatalogSearchInput fullWidth value={searchText} onChange={handleSearchText} />
    </Stack>
  )
}

export default CatalogSearchList;