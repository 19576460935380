import React from 'react';
import { Box, List, Typography } from '@mui/material';
import CatalogSearchBreadcrumbs from './SearchBreadcrumbs';

const CatalogSearchItemList = ({ item, searchText }) => (
  <Box>
    <Typography variant="h4" color="primary">{item.name}</Typography>
    <List>
      {item.breadcrumbs.map((breadcrumb, key) => (
        <CatalogSearchBreadcrumbs key={`CatalogSearchItemList-breadcumbs-${key}`} searchText={searchText} breadcrumbs={breadcrumb} />
      ))}
    </List>
  </Box>
);

export default CatalogSearchItemList;