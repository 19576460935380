import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import FeatureAccordion from './FeatureAccordion';

const FeatureAccordionGrid = ({ expandedAll, features }) => {
  if (features) {
    return (
      <Grid container spacing={2}>
        {features.map(feature => (
          <Grid xs={12} md={6} lg={4}>
            <FeatureAccordion expandedAll={expandedAll} title={feature.name} sync={feature.sync} subjects={feature.subjects}/>
          </Grid>
        ))}
      </Grid>
    )
  }

  return null;
}

export default FeatureAccordionGrid;