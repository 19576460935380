import UserRegisterPage from '../pages/auth/UserRegisterPage';
import {
  HomePage,
  CatalogPage,
  FileList,
  RecoveryRequestToken,
  RecoveryEnterToken,
  RecoveryResetPassword,
  LoginPage,
  DashboardPage,
  UserVerifyPage,
  HomeEdit
} from '../pages';

const routesConfig = [
  {
    path: '',
    subRoutes: [
      {
        path: 'login',
        element: <LoginPage />,
        access: 'unauthenticated',
      },
      {
        path: 'register',
        element: <UserRegisterPage />,
        access: 'unauthenticated',
        subRoutes: [
          {
            path: 'verify',
            access: 'unauthenticated',
            element: <UserVerifyPage />
          },
        ]
      },
      {
        path: 'recovery',
        access: 'unauthenticated',
        subRoutes: [
          {
            path: 'request-token',
            element: <RecoveryRequestToken />,
            access: 'unauthenticated',
          },
          {
            path: 'enter-token',
            element: <RecoveryEnterToken />,
            access: 'unauthenticated',
          },
          {
            path: 'reset-password',
            element: <RecoveryResetPassword />,
            access: 'unauthenticated',
          }
        ]
      },
      {
        path: 'home',
        element: <HomePage />,
        access: 'authenticated',
        subRoutes: [
          {
            path: 'edit',
            element: <HomeEdit />,
            access: 'authenticated',
            authorizedRoles: ['ROLE_SUPER_ADMIN'],
          }
        ]
      },
      {
        path: 'files',
        element: <FileList />,
        access: 'authenticated',
        authorizedRoles: ['ROLE_SUPER_ADMIN'],
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
        access: 'authenticated',
        authorizedRoles: ['ROLE_SUPER_ADMIN'],
      },
      {
        path: 'catalog',
        element: <div>LIST</div>,
        authorizedRoles: ['ROLE_SUPER_ADMIN'],
        subRoutes: [
          {
            path: ':product',
            element: <CatalogPage />,
            access: 'authenticated',
            authorizedRoles: ['ROLE_SUPER_ADMIN', 'ROLE_USER'],
            subRoutes: [
              {
                path: 'services',
                access: 'authenticated',
                authorizedRoles: ['ROLE_SUPER_ADMIN', 'ROLE_USER'],
                subRoutes: [
                  {
                    path: ':service',
                    element: <CatalogPage />,
                    access: 'authenticated',
                    authorizedRoles: ['ROLE_SUPER_ADMIN', 'ROLE_USER'],
                    subRoutes: [
                      {
                        path: 'components',
                        element: <CatalogPage />,
                        access: 'authenticated',
                        authorizedRoles: ['ROLE_SUPER_ADMIN', 'ROLE_USER'],
                        subRoutes: [
                          {
                            path: ':component',
                            element: <CatalogPage />,
                            authorizedRoles: ['ROLE_SUPER_ADMIN', 'ROLE_USER'],
                            access: 'authenticated',
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
    ]
  },
];

export default routesConfig;