import React from 'react'
import { FormikBootstrapInput } from '@agencelafusee/mui-admin-modules'
import { Card, Stack, IconButton, Divider } from '@mui/material'
import { Field, FieldArray, useFormikContext } from 'formik'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const HomeNewsFieldArray = () => {
  const { values } = useFormikContext();

  return (
    <FieldArray 
      name="news"
      render={(arrayHelpers) => (
        <Card>
          {values?.news?.map((_, index) => (
            <>
              <Stack spacing={2} p={2}>
                <Field
                  label={`Nouveauté ${index + 1}`}
                  name={`news[${index}].title`}
                  component={FormikBootstrapInput}
                  placeholder="Titre"
                  variant="outlined"
                />
                <Field
                  name={`news[${index}].link`}
                  component={FormikBootstrapInput}
                  placeholder="Url"
                  variant="outlined"
                />

                <IconButton
                  sx={{ alignSelf: 'flex-end' }}
                  color="error"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <DeleteOutlineOutlinedIcon color="primary" />
                </IconButton>
              </Stack>
              <Divider />
            </>
          ))}

          <Stack direction="row">
            <IconButton
              color="primary"
              onClick={() =>
                arrayHelpers.push()
              }
            >
              <AddCircleOutlineRoundedIcon color="primary" />
            </IconButton>
          </Stack>
        </Card>
      )}
    />
  )
}

export default HomeNewsFieldArray