export const segments = [
  {
    key: 'GC_IBO',
    label: 'GC/IBO'
  },
  {
    key: 'GF',
    label: 'GF'
  },
  {
    key: 'MID_MARKET',
    label: 'Mid Market'
  },
  {
    key: 'PLLD',
    label: 'Secteur public'
  }
];