import React from 'react'
import { FormLabel, Stack, Typography, FormControlLabel, Radio, Button, Box } from '@mui/material'
import { Field } from 'formik'
import AuthLayout from '../../layouts/AuthLayout'
import { FormikBootstrapInput, FormikRadioGroup, FormikSubmit } from '@agencelafusee/mui-admin-modules';
import axios from '../../api/axios';
import Create from '../../components/shared/Create';
import Query from '../../components/shared/Query';
import SimpleForm from '../../components/shared/SimpleForm';
import { Link } from 'react-router-dom';

const UserRegisterPage = () => {
  const [success, setSuccess] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const handleSubmit = (mutate, values) => {
    mutate(values);
  };

  const handleSuccess = (_, values) => {
    setSuccess(true);
    setEmail(values.email);
  }

  return (
    <AuthLayout
      title="Créer un compte"
      links={!success && [
        {
          to: '/recovery/request-token',
          label: 'Mot de passe oublié',
        },
        {
          to: '/login',
          label: 'Connexion',
        }
      ]}
    >
      {success ? (
        <Stack spacing={2}>
          <Box>
            <Typography display="inline">Vous allez recevoir un mail à l'adresse</Typography>
            <Typography display="inline" color="primary">{` ${email} `}</Typography>
            <Typography display="inline">pour valider votre compte.</Typography>
          </Box>
          <div>
            <Button variant="contained" component={Link} to="/login">Retourner à la page de connexion</Button>
          </div>
        </Stack>
      ): (
        <Create
          initialValues={{
            email: '',
            segment: 1,
            function: 'ICE',
          }}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          query={(data) => axios.post('/users', data)}
        >
          <SimpleForm withAlert>
            <Stack spacing={3} direction="column" textAlign="center" alignItems="center" justifyContent="center">
              <Field
                component={FormikBootstrapInput}
                placeholder="____________ @arval.fr"
                required
                label="Adresse e-mail "
                name="email"
                variant="outlined"
              />
              <Stack>
                <FormLabel>Choisissez votre segment commercial</FormLabel>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Query
                    queryKey='segments'
                    queryFn={(() => axios.get('/data/segments').then(res => res.data?.data))}
                  >
                    {({ data }) => (
                      <FormikRadioGroup
                        required
                        name="segment"
                        key={`CheckboxGroup`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        {data?.map(segment => (
                          <FormControlLabel 
                            control={<Radio value={segment.id} />}
                            label={<Typography>{segment.name}</Typography>}
                          />
                        ))}
                      </FormikRadioGroup>
                    )}
                  </Query>
                </Stack>
              </Stack>

              <Stack>
                <FormLabel>Quelle fonction occupez-vous ?</FormLabel>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <FormikRadioGroup
                  required
                  name="function"
                  key={`CheckboxGroup`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                  >
                    <FormControlLabel 
                      control={<Radio value="ICE" />}
                      label={<Typography>ICE</Typography>}
                    />
                    <FormControlLabel 
                      control={<Radio value="rdc" />}
                      label={<Typography>RDC</Typography>}
                    />
                    <FormControlLabel 
                      control={<Radio value="atc" />}
                      label={<Typography>ATC</Typography>}
                    />
                    <FormControlLabel 
                      control={<Radio value="other" />}
                      label={<Typography>Autres</Typography>}
                    />
                  </FormikRadioGroup>
                </Stack>
              </Stack>

              <FormikSubmit>S'inscrire</FormikSubmit>
              <Typography>
                <Typography component="span" color="error">*</Typography>
                {" "}Champ obligatoire
              </Typography>
            </Stack>
          </SimpleForm>
        </Create>
      )}
    </AuthLayout>
  )
}

export default UserRegisterPage