import React from 'react'
import { FastField, Form } from 'formik'
import { Stack, Typography } from '@mui/material'
import AuthLayout from '../../layouts/AuthLayout'
import { FormikBootstrapInput, FormikSubmit } from '@agencelafusee/mui-admin-modules';
import axios from '../../api/axios';
import { useNavigate } from 'react-router';
import Create from '../../components/shared/Create';

const Page = () => {
  const navigate = useNavigate();

  const handleSuccess = (_, variables) => {
    navigate('/recovery/enter-token', {
      state: {
        email: variables.email
      }
    });
  }

  const handleSubmit = (mutate, values) => {
    mutate(values);
  }

  return (
    <AuthLayout
      title="MOT DE PASSE OUBLIÉ"
      description="Réinitialisez votre mot de passe"
      links={[
        {
          to: '/login',
          label: 'Connexion',
        },
        {
          to: '/register',
          label: 'Créer un compte',
        }
      ]}
    >
      <Create
        initialValues={{ email: '' }}
        query={(data) => axios.post('/auth/reset/request', data)}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      >
        <Form>
          <Stack spacing={3} direction="column" textAlign="center" alignItems="center" justifyContent="center">
            <FastField 
              component={FormikBootstrapInput}
              required
              placeholder="____________ @arval.fr"
              label="Adresse e-mail"
              name="email"
              variant="outlined"
            />
            <FormikSubmit>Réinitialiser</FormikSubmit>
            <Typography>
              <Typography component="span" color="error">*</Typography>
              {" "}Champ obligatoire
            </Typography>
          </Stack>
        </Form>
      </Create>
    </AuthLayout>
  )
}

export default Page;