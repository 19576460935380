import React from 'react'
import { 
  AppBar, 
  Toolbar, 
  IconButton,
  Box, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemText, 
  Typography, 
  Divider, 
  Drawer,
  styled
} from '@mui/material'
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import LeftMenu from './LeftMenu';
import { Link } from 'react-router-dom';
import ArvalLogo from '../../assets/img/arvalLogo.png';

const BoxStyled =  styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    backgroundColor: 'none',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: 80,
    backgroundColor: 'common.white',
  }
}))

const TopMenu = (props) => {
  return (
    <BoxStyled>
      <AppBar component="nav" sx={{ zIndex: 0, backgroundColor: { xs: "white", md: "initial" }, zIndex: { xs: 999, md: 'initial'} }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Link to="/home">
            <img src={ArvalLogo} width="151px"  alt="arval-logo" />
          </Link>
          <LeftMenu
            action={
              <IconButton
                color="primary"
                aria-label="open drawer"
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            }
          />
        </Toolbar>
      </AppBar>
    </BoxStyled>
  )
}

TopMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default TopMenu